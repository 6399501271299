require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#00ABC2',
        accent: '#B13F1A',
        secondary: '#8A8D93',
        success: '#86b809',
        info: '#9155FD',
        warning: '#E6B371',
        error: '#FF4C51',
        mobilePrimary: '#1f4168',
        mobileSecondary: '#000000',
      },
      dark: {
        primary: '#00ABC2',
        accent: '#B13F1A',
        secondary: '#8A8D93',
        success: '#86b809',
        info: '#9155FD',
        warning: '#E6B371',
        error: '#FF4C51',
        mobilePrimary: '#1f4168',
        mobileSecondary: '#000000',
      },
    },
  },
}
