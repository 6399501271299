<template>
    <div class="mr-4 text-sm">
        <select v-model="$i18n.locale">
            <option
                :value="locale.code"
                v-for="locale in locales"
                :key="locale.code"
            >
                {{ locale.name }}
            </option>
        </select>
    </div>
</template>
<script>
import { getSupportedLocales } from "@/utils/i18n/supported-locales";
export default {
    data: () => ({ locales: getSupportedLocales() }),
};
</script>

<style>
select {
    border: 0px;
    outline: 0px;
}
</style>
